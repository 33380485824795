<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body p-0">
            <div class="mm-edit-list usr-edit">
              <tab-nav :pills="true" extraclass="mm-edit-profile d-flex">
                <tab-nav-items
                  :active="true"
                  id="personal"
                  dataToggle="pill"
                  ariaControls="personal-info"
                  :title="$t('userMsgs.personalInfo')"
                  liClass=" col-md-6 p-0"
                />
                <tab-nav-items
                  :active="false"
                  id="chang"
                  dataToggle="pill"
                  ariaControls="change-pwd"
                  :title="$t('userMsgs.changePass')"
                  liClass=" col-md-6 p-0"
                />
              </tab-nav>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="mm-edit-list-data">
          <tab-content>
            <tab-content-item
              :active="true"
              id="personal-info"
              aria-labelled-by="personal"
            >
              <div class="card">
                <div class="card-header d-flex justify-content-between">
                  <div class="mm-header-title">
                    <h4 class="card-title">
                      {{ $t("userMsgs.personalInfo") }}
                    </h4>
                  </div>
                </div>
                <div class="card-body">
                  <form @submit.prevent="onProfileSubmit">
                    <div class="form-group row align-items-center">
                      <div class="col-md-12">
                        <div class="profile-img-edit">
                          <div class="crm-profile-img-edit">
                            <b-avatar
                              v-if="
                                getLoggedInUser.first_name &&
                                getLoggedInUser.last_name
                              "
                              variant="primary"
                              size="90px"
                              :text="`${getLoggedInUser.first_name[0].toUpperCase()}${getLoggedInUser.last_name[0].toUpperCase()}`"
                            />
                            <b-avatar variant="primary" size="90px" v-else />
                            <!-- <img
                              class="crm-profile-pic rounded-circle avatar-100"
                              src="@/assets/images/user/1.jpg"
                              alt="profile-pic"
                            /> -->
                            <div class="crm-p-image bg-primary">
                              <i class="fas fa-pen upload-button"></i>
                              <input
                                class="file-upload"
                                type="file"
                                accept="image/*"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center">
                      <div class="form-group col-sm-6">
                        <label for="fname">{{
                          $t("userMsgs.firstName")
                        }}</label>
                        <input
                          type="text"
                          class="form-control"
                          id="fname"
                          :placeholder="$t('userMsgs.firstNamePlaceholder')"
                          v-model.trim="firstName"
                          required
                        />
                      </div>
                      <div class="form-group col-sm-6">
                        <label for="lname">{{ $t("userMsgs.lastName") }}</label>
                        <input
                          type="text"
                          class="form-control"
                          id="lname"
                          :placeholder="$t('userMsgs.lastNamePlaceholder')"
                          v-model.trim="lastName"
                          required
                        />
                      </div>
                      <div class="form-group col-sm-6">
                        <label for="email">{{ $t("email") }}</label>
                        <input
                          type="email"
                          class="form-control"
                          id="email"
                          placeholder="example@company.com"
                          :value="email"
                          disabled
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label for="username">{{ $t("username") }}</label>
                        <input
                          class="form-control"
                          id="username"
                          :value="getUsername(getLoggedInUser)"
                          disabled
                        />
                      </div>
                    </div>
                    <div class="text-right">
                      <!-- <button type="reset" class="btn mm-bg-danger">
                        Cancel
                      </button> -->
                      <!-- <button type="submit" class="btn btn-primary">
                        Update
                      </button> -->

                      <b-button
                        type="submit"
                        variant="primary"
                        style="min-width: 100px"
                        :disabled="isLoading"
                      >
                        <i
                          v-if="isLoading"
                          class="fas fa-circle-notch fa-spin"
                        ></i>
                        <span v-else>{{ $t("update") }}</span>
                      </b-button>
                    </div>
                  </form>
                </div>
              </div>
            </tab-content-item>
            <tab-content-item
              :active="false"
              id="change-pwd"
              aria-labelled-by="chang"
            >
              <div class="card">
                <div class="card-header d-flex justify-content-between">
                  <div class="mm-header-title">
                    <h4 class="card-title">{{ $t("userMsgs.changePass") }}</h4>
                  </div>
                </div>
                <div class="card-body">
                  <form @submit.prevent="onPasswordUpdateSubmit">
                    <div class="row">
                      <div class="form-group col-sm-12">
                        <label for="cpass">{{ $t("userMsgs.currPass") }}</label>
                        <input
                          type="Password"
                          class="form-control"
                          :class="currPassError ? 'is-invalid' : ''"
                          id="cpass"
                          v-model="currPassword"
                          placeholder="********"
                          minlength="3"
                          required
                        />
                        <div
                          class="invalid-feedback"
                          v-if="currPassError === INCORRECT_CURR_PASS"
                        >
                          {{ $t("recoverPassMsgs.incorrectCurrPass") }}
                        </div>
                      </div>

                      <div class="form-group col-sm-6">
                        <label for="npass">{{ $t("userMsgs.newPass") }}</label>
                        <input
                          type="Password"
                          class="form-control"
                          :class="passError ? 'is-invalid' : ''"
                          id="npass"
                          v-model="newPassword"
                          placeholder="********"
                          minlength="3"
                          required
                        />
                        <div
                          class="invalid-feedback"
                          v-if="passError === PASSWORDS_NOT_MATCH"
                        >
                          {{ $t("recoverPassMsgs.passwordsNotMatch") }}
                        </div>
                        <div
                          class="invalid-feedback"
                          v-if="passError === PASSWORDS_MIN_LENGTH"
                        >
                          {{ $t("recoverPassMsgs.passwordsMinLength") }}
                        </div>
                        <div
                          class="invalid-feedback"
                          v-if="passError === PASSWORDS_ALL_NUMERIC"
                        >
                          {{ $t("recoverPassMsgs.passwordsAllNumeric") }}
                        </div>
                      </div>
                      <div class="form-group col-sm-6">
                        <label for="vpass">{{
                          $t("userMsgs.repeatNewPass")
                        }}</label>
                        <input
                          type="Password"
                          class="form-control"
                          id="vpass"
                          v-model="repeatPassword"
                          placeholder="********"
                          minlength="3"
                          required
                        />
                      </div>
                    </div>
                    <div class="text-right">
                      <!-- <button type="reset" class="btn mm-bg-danger">
                        Cancel
                      </button>
                      <button type="submit" class="btn btn-primary">
                        Update
                      </button> -->

                      <b-button
                        type="submit"
                        variant="primary"
                        style="min-width: 100px"
                        :disabled="isLoading"
                      >
                        <i
                          v-if="isLoading"
                          class="fas fa-circle-notch fa-spin"
                        ></i>
                        <span v-else>{{ $t("update") }}</span>
                      </b-button>
                    </div>
                  </form>
                </div>
              </div>
            </tab-content-item>
          </tab-content>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tabNav from "../../components/tab/tab-nav.vue";
import { mapGetters, mapActions } from "vuex";
import {
  PASSWORD_VALIDATIONS,
  PASSWORD_ALL_NUMERIC_REGEX,
} from "../../common/constants";
import { getUsername } from '../../common/utils';

export default {
  components: { tabNav },
  name: "MyProfile",

  data() {
    return {
      email: "",

      firstName: "",
      lastName: "",
      currPassword: "",
      newPassword: "",
      repeatPassword: "",

      isLoading: false,

      passError: null,
      currPassError: null,
      ...PASSWORD_VALIDATIONS,
    };
  },

  methods: {
    ...mapActions(["updateMyProfile", "updateMyPassword"]),
    getUsername,

    async onPasswordUpdateSubmit() {
      this.passError = this.currPassError = null;
      if (this.newPassword !== this.repeatPassword) {
        this.passError = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
      } else if (this.newPassword.length < 3) {
        this.passError = PASSWORD_VALIDATIONS.PASSWORDS_MIN_LENGTH;
      } else if (PASSWORD_ALL_NUMERIC_REGEX.test(this.newPassword)) {
        this.passError = PASSWORD_VALIDATIONS.PASSWORDS_ALL_NUMERIC;
      } else {
        this.isLoading = true;

        try {
          await this.updateMyPassword({
            ...(this.newPassword &&
              this.currPassword && {
                old_password: this.currPassword,
                new_password: this.newPassword,
              }),
          });
          this.currPassword = this.newPassword = this.repeatPassword = "";
          this.makeToast("success", this.$t("userMsgs.profileUpdated"));
        } catch (error) {
          if (error.response.status === 403) {
            this.currPassError = PASSWORD_VALIDATIONS.INCORRECT_CURR_PASS;
          }
          this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
        }

        this.isLoading = false;
      }
    },

    async onProfileSubmit() {
      this.isLoading = true;

      try {
        await this.updateMyProfile({
          first_name: this.firstName,
          last_name: this.lastName,
        });
        this.makeToast("success", this.$t("userMsgs.profileUpdated"));
      } catch (error) {
        this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
      }

      this.isLoading = false;
    },

    makeToast(variant = null, msg) {
      this.$bvToast.toast(msg, {
        variant: variant,
        noCloseButton: true,
        autoHideDelay: 2500,
      });
    },
  },

  computed: {
    ...mapGetters(["getLoggedInUser"]),
  },

  mounted() {
    this.firstName = this.getLoggedInUser.first_name;
    this.lastName = this.getLoggedInUser.last_name;
    this.email = this.getLoggedInUser.email;
  },
};
</script>
